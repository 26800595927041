import { Box, Container, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cer1 from '../../../documents/certificado DigitalOcean.pdf';
import cer2 from '../../../documents/Cyber_Threat_Management_Badge20231223-29-4j5xae.pdf';
import cer3 from '../../../documents/Endpoint_Security_Badge20231223-29-5b3551.pdf';
import cer4 from '../../../documents/Introduction_to_Cybersecurity_Badge20231223-29-um0q7h.pdf';
import carta1 from '../../../documents/recomendacion ECOSUR.pdf';


const dataInfo = [
    {
        titulo: 'Certificaciones',
        certificados: [
            {
                title: 'Endpoint Security',
                url: cer3
            },
            {
                title: 'Introduction to Cybersecurity',
                url: cer4
            },
            {
                title: 'Cyber Threat Management',
                url: cer2
            },
            {
                title: 'VPS Linux en DigitalOcean',
                url: cer1
            }
        ]
    }, 
    {
        titulo: 'Cartas de recomendación',
        certificados: [
            {
                title: 'El Colegio de la Frontera Sur (ECOSUR)',
                url: carta1
            },
        ]
    }
]

const Certificaciones = () =>{
      return (
        <>
        <Box  sx={{marginTop:'7rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, color:'#825323', fontFamily:'Oswald', fontWeight:'600'}}>
                            Certificaciones y recomendaciones
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        <Box sx={{width:'100%', marginTop:'3rem'}}>
        <Container>
            <Box sx={{marginTop:'2rem'}}>
                {
                    dataInfo.map((item, index)=>(
                        <Accordion sx={{border: '10px solid #3aa82d',
                        borderRadius: '10px',
                        background: 'rgba(255, 255, 255, 0.5)', marginBottom:'1rem'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{color:'#BDB03E'}} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{color:'#BDB03E'}}
                            >
                                <Typography sx={{paddingLeft:'5px', fontSize:{md:'2rem', sm:'1.5rem'}}}>{item.titulo}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Container 
                                sx={{
                                    padding:{xs:'1rem', lg: '2rem'}, 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    textAlign: 'left',}}
                                >
                                    {
                                        item.certificados.map((cer)=>(
                                            <Typography  sx={{fontSize:{md:'1.5rem', sm:'1rem', marginBottom:'1rem'}}}><a href={cer.url} download style={{color:'black'}} >&#8226; {cer.title} (descargar)</a></Typography>
                                        ))
                                    }
                                </Container>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Box>
        </Container>
        </Box>
        </>
      );
}

export default Certificaciones;