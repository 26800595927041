import FormContac from "../../modules/contacto";


function Contact() {
    return(
        <>
        <FormContac/>
        </>
    )
}

export default Contact;