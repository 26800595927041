import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import avatar from '../../images/AVATAR2.webp';


const FormContac = () =>{

    
    return(
        <>
        <Box sx={{marginTop:'2rem'}}>
            <Container>
                <Box>
                    <Grid container spacing={3} justifyContent='center'>
                        <Grid item md={6} sm={6} xs={12} >
                        <>
                            <Box
                                sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: '#3aa82d', padding:'3px' }}>
                                    <CommentIcon/>
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                Háblame de tu proyecto
                                </Typography>
                                <Box component="form" noValidate  sx={{ mt: 1 }} action="https://formspree.io/f/xzbnzwgb" method='POST'>
                                    <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="email"
                                    label="Correo electrónico"
                                    type="email"
                                    id="email"
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="title"
                                    label="Título"
                                    type="text"
                                    id="title"
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="comentario"
                                    label="Agrega un comentario"
                                    multiline
                                    rows={5}
                                    type="text"
                                    id="comentario"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, backgroundColor:'#3aa82d', '&:hover': {
                                        backgroundColor: '#277a1d'
                                    },}}
                                >
                                    Enviar
                                    <SendIcon sx={{marginLeft:'0.5rem'}} />
                                </Button>
                                </Box>
                            </Box>
                            </>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} container>
                            <Box
                                sx={{
                                width:'100%',
                                display: 'flex',
                                }}
                            >
                                <img src={avatar} alt='Avatar'  style={{margin:'auto', width:'70%'}}></img>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
        </>
    )
}

export default FormContac;