import React, { useEffect } from 'react';
import { Route, HashRouter as Router, Routes, useLocation } from 'react-router-dom';
import Footer from './modules/footer';
import Menu from './modules/head_menu';
import Home from './pages/home';
import AboutMe from './pages/aboutMe';
import Works from './pages/works';
import Contact from './pages/contact';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Menu/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path='/acerca' element={<AboutMe/>}/>
        <Route path='/trabajos' element={<Works/>}/>
        <Route path='/contacto' element={<Contact/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
