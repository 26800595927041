import Intro from "./components/intro";
import SliderStack from "./components/stack";
import AvatarAlcantarilla from '../../images/AVATAR1.webp';
import {Box} from '@mui/material'
import Resume from "./components/resumen";
import Job from "./components/trabajo";


const HomeContainer = () => {
    return(
        <Box sx={{overflowX:'hidden'}}>
        <Intro/>
        <Resume/>
        <SliderStack/>
        <Job/>
        <Box sx={{ display:'flex', justifyContent:'center', marginTop:'8rem'}}>
            <img src={AvatarAlcantarilla} width='300px' style={{margin:'auto'}} ></img>
        </Box>
        </Box>
    )
}

export default HomeContainer;