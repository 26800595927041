import { Box, Container, Grid, Typography, Card, CardActionArea, CardContent, CardActions, CardMedia, Button } from '@mui/material';
import trabajo1 from '../../../images/neowork.png';
import trabajo2 from '../../../images/sondtour.png';
import trabajo3 from '../../../images/tuchtlan.png';

const data = [
    {
        title: 'Front Sistema de Rifas para Neowork',
        descripcion: 'Aparatado visual del sistema de sorteos de Neowork. Empresa que ofrece a sus socios promociones en viajes y demás ofertas relacionadas al rubro.',
        image: trabajo1,
        alt: 'Sistema de Sorteos',
        url:'https://sorteos.neoworkglobal.com/'
    },
    {
        title: 'Front Administrador de Contenido',
        descripcion: 'Ejemplo de un gestor de contanido, en este caso es una aplicación web enfocada meramente en que sea con laptop, por lo cual puede ser transformada a una app de escritorio',
        image: trabajo2,
        alt:'Gestión de contenido',
        url:'https://sondtouradmin.web.app/'
    },
    {
        title: 'Front Blog de Asociación Juvenil',
        descripcion: 'Sitio web de una asociación juvenil, en donde pueden visualizar su historia, dirección, labor. Así como un formulario para poder ser miembro, lo que implica conexiones externas.',
        image: trabajo3,
        alt: 'Blog',
        url:'https://tuchtlanajef.web.app/'
    },
]


const Works = () =>{
    
      return (
        <>
        <Box  sx={{marginTop:'7rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, color:'#825323', fontFamily:'Oswald', fontWeight:'600'}}>
                            Trabajos Recientes
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        <Box sx={{width:'100%', marginTop:'3rem'}}>
        <Container>
        <Box sx={{ width:'100%'}}>
            <Grid container spacing={3} >
                {
                    data.map((item, index)=>(
                        <Grid key={index} item md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ width: 350, boxShadow:'0px 5px 5px rgba(0, 0, 0, 0.5)', margin:'auto' }}>
                                <CardActionArea>
                                    <CardMedia
                                    component="img"
                                    height="200"
                                    image={item.image}
                                    alt={item.alt}
                                    />
                                    <CardContent>
                                    <Typography textAlign={'left'} gutterBottom variant="h5" component="div" fontFamily='Oswald' sx={{color:'#825323'}}>
                                        {item.title}
                                    </Typography>
                                    <Typography textAlign={'justify'} variant="body2" color="text.secondary">
                                        {item.descripcion}
                                    </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button target='_blank' href={item.url} sx={{color:'#3aa82d'}}>
                                            Visitar
                                        </Button>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
        </Container>
        </Box>
        </>
      );
}

export default Works;