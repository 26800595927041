import Box from '@mui/material/Box';
import Certificaciones from "./components/certificaciones";
import Experiencia from "./components/experiencia";
import ResumenMe from "./components/resumen";


const AboutMeContainer = () =>{
    return(
        <Box sx={{overflowX:'hidden'}}>
        <ResumenMe/>
        <Experiencia/>
        <Certificaciones/>
        </Box>
    )
}

export default AboutMeContainer;