import HomeContainer from "../../modules/tableHome";


function Home() {
    return(
        <>
        <HomeContainer/>
        </>
    )
}

export default Home;