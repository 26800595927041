import WorksContainer from "../../modules/tableWors";


function Works() {
    return(
        <>
        <WorksContainer/>
        </>
    )
}

export default Works;