import { Box, Container, Grid, Typography, Button } from '@mui/material';
import ecosur from '../../../images/ecosur.jpg';
import up from '../../../images/up.webp';
import avatar from '../../../images/AVATAR5.webp';

const Data = [
    {
        id:1,
        title: 'Servicio social realizado en ECOSUR',
        descripcion: 'Tuve la fortuna de realizar mi servicio en El Colegio de la Frontera Sur (ECOSUR). Donde participe activamente en la realización de componentes visuales del sistema de información de alumnos y docentes. Al igual que tuve las primeras incursiones en lenguajes como C#. Aunque igual apliqué mis conocimientos previos de Typescript y React.',
        image: ecosur,
        alt: 'Ecosur'
    },
    {
        id:2,
        title: 'Estudios de nivel superior en la UP Chiapas',
        descripcion: 'En la Universidad Politécnica de Chiapas obtuve no solo el conocimiento para poder llevar productos de software de alta calidad y el cnocimiento de cada proceso. También obtuve y apliqué conocimientos de lenguajes como Java, Python y Javascript. Aplicándolos en varias ramas como Inteligencia Artificial, procesamiento y minería de datos, APIS y desarrollo web.',
        image: up,
        alt: 'UPChiapas'
    },
    {
        id:3,
        title: 'Desarrollo de proyectos independientes',
        descripcion: 'Como una persona que se considera proactiva, trato siempre de mantenerme informado así como practicar con varios proyectos, sobretodo enfocado en desarrollo web, y realizar pruebas que mejoran la lógica de programación. Transformo ideas en interfaces interactivas y atractivas, buscando siempre la combinación perfecta entre diseño y funcionalidad. No obstante, mi destreza no se limita al FrontEnd. Mi habilidad para enfrentar desafíos va más allá, extendiéndose al BackEnd con soluciones robustas y eficientes.',
        image: avatar,
        alt: 'LeoEspinosa'
    },
]

const Experiencia = () =>{
    
      return (
        <>
        <Box  sx={{marginTop:'7rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, color:'#825323', fontFamily:'Oswald', fontWeight:'600'}}>
                            Experiencia
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        <Box sx={{width:'100%', marginTop:'3rem'}}>
        <Container>
            <Grid container spacing={3}>
                {
                    Data.map((item)=>(
                        <>
                        <Grid item key={item.id} md={4} xs={12} >
                            <Box sx={{flexDirection:'column', display:'flex', justifyContent:'center', padding:'1rem'}}>
                                <img src={item.image} alt={item.alt} height='200px' style={{margin:'auto'}}></img>
                                <Typography  sx={{textAlign:'center', color:'#3aa82d', fontFamily:'Oswald', fontSize:{md:'2rem', sm:'1.5rem'}, marginTop:'1rem'}}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{textAlign:'justify', color:'#825323', fontFamily:'Oswald', fontSize:{md:'1.5rem', sm:'1rem'}, marginTop:'1rem'}}>
                                    {item.descripcion}
                                </Typography>
                            </Box>
                        </Grid>
                        </>
                    ))
                }
            </Grid>
            <Box sx={{width:'100%', textAlign:'center', marginTop:'2rem'}}>
            <Button href='/#/trabajos' sx={{color:'#3aa82d'}} size='large'>
                Ver Trabajos
            </Button>
            </Box>
        </Container>

        </Box>
        </>
      );
}

export default Experiencia;