import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Logo from '../../images/logoLE.png'


const drawerWidth = 240;
const navItems = [
  {
    name: 'Inicio',
    url: '/'
  },
  {
    name: 'Acerca de mi',
    url: '/#/acerca'
  },
  {
    name:'Trabajos',
    url:'/#/trabajos'
  },
  {
    name: 'Contacto',
    url: '/#/contacto'} ];

function Menu(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center'}}>
    <img src={Logo} height='150px' />
      <Divider sx={{backgroundColor:'#825323'}} />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton href={item.url} sx={{ textAlign: 'center', color: '#87877E'}}>
              <ListItemText  primary={item.name} sx={{fontFamily:'BebasNeue', fontSize:'1rem' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{backgroundColor:'#825323'}} />
    </Box>
  );


  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    <Box sx={{ display: 'flex', flexDirection: 'column'  }}>
      <nav >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor:'#fffff' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <AppBar sx={{backgroundColor:'white'}} component="nav">
        <Toolbar style={{marginBottom:{sm:'1rem', xs:'0.8rem'}, marginTop:'1rem',  textAlign: {xs: 'center'}}}>
          <Box sx={{ flex: '0 0 10%', display: { sm: 'none' } }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              size='large'
              onClick={handleDrawerToggle}
              sx={{ mr:'1rem', ml:'0.5rem', color:'#87877E' }}
            >
              <MenuIcon sx={{fontSize:'2rem', color:'#825323'}} />
            </IconButton>
          </Box>
            <Box sx={{ display: { sm: 'block', xs: 'none' }, marginRight: '1rem', marginBottom:'0.5rem' }}>
              <Link href='/' sx={{textDecoration:'none', display:'flex', alignItems:'center'}}>
                <img src={Logo} height='100px' />
                <Typography variant='h1' sx={{fontSize:'3.5rem', color:'#3aa82d', textDecoration:'none',  fontFamily:'Oswald'}}>Leonardo Espinosa</Typography>
              </Link>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: { sm: 'none', xs: 'flex' },
                marginRight: '1rem',
                justifyContent: 'center',
                marginBottom:'0.5rem'
              }}
            >
              <Link href='/' sx={{textDecoration:'none', fontFamily:'Oswald'}}>
                <Typography variant='h1' sx={{fontSize:'1.5rem', color:'#3aa82d', textDecoration:'none', fontFamily:'Oswald'}}>Leonardo Espinosa</Typography>
              </Link>
            </Box>
          <Box sx={{display: {sm: 'none', xs:'block' }, width:'10%' }}/>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, marginLeft:'auto'}}>
            {navItems.map((item) => (
              <Button size='large' href={item.url} key={item.name} sx={{ color: '#825323', marginLeft: '1rem', fontSize:'1rem', fontWeight:'550' }}>
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    <Box sx={{ height:{sm:'2.5rem', xs:'2rem'}, marginTop:'2.8rem' }}/>
    </>
  );
}


export default Menu;