import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import avatar from '../../../images/AVATAR4.webp';



const Invitation = () =>{
    return(
        <>
        <Box sx={{marginTop:'8rem', padding:'3rem'}}>
            <Grid container spacing={3} justifyContent='center'>
                <Grid item md={6} sm={6} xs={12} >
                <>
                    <Box
                    sx={{textAlign:{md:'right', xs:'center'}}}
                    >
                        <img src={avatar} alt='avatar' width='300px' ></img>
                    </Box>
                    </>
                </Grid>
                <Grid item md={4} sm={5} xs={12} container alignItems="center">
                    <Box
                        sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection:'column',
                        }}
                    >
                        <Typography variant='h4' sx={{ fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'550', textAlign:'center', marginBottom:'1rem', color:'#825323'}}>
                            Quieres un producto de software: <a href='/#/contacto'  style={{color:'#3aa82d', cursor:'pointer'}}>Contáctame</a>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={2} sm={1} xs={false} />
            </Grid>
        </Box>
        </>
    )
}

export default Invitation;