import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography, CardActions, Button } from '@mui/material';
import Trabajo from '../../../images/neowork.png'



const Job = () =>{
    return(
        <>
        <Box sx={{marginTop:'8rem'}}>
            <Grid container spacing={3} justifyContent='center'>
                <Grid item md={6} sm={6} xs={12} >
                <>
                    <Box
                    sx={{display:'flex', justifyContent:'center'}}
                    >
                        <Card sx={{ width: 350, boxShadow:'0px 5px 5px rgba(0, 0, 0, 0.5)', margin:'auto' }}>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                height="200"
                                image={Trabajo}
                                />
                                <CardContent>
                                <Typography textAlign={'left'} gutterBottom variant="h5" component="div" fontFamily='Oswald' sx={{color:'#825323'}}>
                                    <span style={{color:'black'}} >Último trabajo:</span> Front Sistema de Rifas para Neowork
                                </Typography>
                                <Typography textAlign={'justify'} variant="body2" color="text.secondary">
                                    Aparatado visual del sistema de sorteos de Neowork. Empresa que ofrece a sus socios promociones en viajes y demás ofertas relacionadas al rubro. 
                                </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button target='_blank' href='https://neoworksorteos.web.app/' sx={{color:'#3aa82d'}}>
                                        Visitar
                                    </Button>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </Box>
                    </>
                </Grid>
                <Grid item md={6} sm={6} xs={12} container alignItems="center">
                    <Box
                        sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection:'column',
                        }}
                    >
                        <Typography variant='h4' sx={{ fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'550', textAlign:'center', marginBottom:'1rem', color:'#825323'}}>
                            Visita otros trabajos: <a href='/#/trabajos'  style={{color:'#3aa82d', cursor:'pointer'}}>Aqui</a>
                        </Typography>
                        <Typography variant='h4' sx={{ fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'550', textAlign:'center', marginBottom:'1rem', color:'#825323'}}>
                            Quieres un producto de software: <a href='/#/contacto'  style={{color:'#3aa82d', cursor:'pointer'}}>Contáctame</a>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

export default Job;