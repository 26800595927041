import { Box, Container, Grid, Typography } from '@mui/material';
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import javascript from '../../../images/icons8-javascript.svg';
import java from '../../../images/icons8-logotipo-de-java-coffee-cup.svg';
import spring from '../../../images/icons8-logotipo-de-primavera.svg';
import typescript from '../../../images/icons8-mecanografiado.svg';
import sql from '../../../images/icons8-mysql-50.png';
import node from '../../../images/icons8-nodo-js.svg';
import post from '../../../images/icons8-postgresql.svg';
import python from '../../../images/icons8-python.svg';
import reactLogo from '../../../images/icons8-reaccionar.svg';


const Data = [
    {
        id: 1,
        name: 'JavaScript',
        logo: javascript
    },
    {
        id: 2,
        name: 'Java',
        logo: java
    },
    {
        id: 3,
        name: 'TypeScript',
        logo: typescript
    },
    {
        id: 4,
        name: 'NodeJS',
        logo: node
    },
    {
        id: 9,
        name: 'Python',
        logo: python
    },
    {
        id: 5,
        name: 'React',
        logo: reactLogo
    },
    {
        id: 6,
        name: 'MySql',
        logo: sql
    },
    {
        id: 7,
        name: 'Postgresql',
        logo: post
    },
    {
        id: 8,
        name: 'SpringBoot',
        logo: spring
    },
]

const SliderStack = () =>{
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      return (
        <>
        <Box  sx={{marginTop:'7rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, color:'#825323', fontFamily:'Oswald', fontWeight:'600'}}>
                            Tecnologías y lenguajes manejados
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        <Box sx={{width:'100%', marginTop:'3rem'}}>
        <Container>
            <div>
            <Slider {...settings}>
                {
                    Data.map((item)=>(
                        <Box key={item.id}  sx={{ justifyContent: 'center', display:'flex', marginBottom:'2rem'}} >
                            <img src={item.logo} alt={item.name} width='150px' style={{ margin:'auto'}}></img>
                            <Typography gutterBottom sx={{color:'#3aa82d', textAlign:'center', marginTop:'1rem', fontWeight:'550'}} >{item.name}</Typography>
                        </Box>
                    ))
                }
            </Slider>
            </div>
        </Container>
        </Box>
        </>
      );
}

export default SliderStack;