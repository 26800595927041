import { Box, Grid, Typography, Button } from "@mui/material";
import AvatarLeo from '../../../images/AVATAR3.webp'


function ResumenMe() {
    return(
        <>
        <Box sx={{ width:'100%', marginTop:{md:'4rem', xs:'0.5rem'}}}>
            <Grid container spacing={2} style={{display:'flex'}}>
                <Grid  item md={5} sm={12}>
                    <Box sx={{width:'100%', height:'100%', textAlign:{md:'right', xs:'center'}}} >
                        <img src={AvatarLeo} width='60%'  />
                    </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12} >
                    <Box sx={{width:'100%', height:'100%',display:'flex', alignItems:'center'}} >
                    <Box sx={{padding:'1rem'}}>
                        <Typography variant='body1' sx={{fontSize:{xs:'1.2rem', md:'2rem'}, fontFamily:'Oswald', color:'#825323'}}>
                        ¡Bienvenido a mi rincón digital! Soy Leonardo Espinosa, un apasionado desarrollador de software con un enfoque dinámico y creativo en el mundo del desarrollo web. Egresado de la Universidad Politécnica de Chiapas, mi viaje en el fascinante universo de la programación comenzó con una sólida base académica y ha evolucionado hacia la creación de experiencias web cautivadoras.
                        </Typography>
                    </Box>
                    </Box>
                </Grid>
                <Grid item md={1} sm={false} xs={false} />
            </Grid>
        </Box>
        </>
    )
}

export default ResumenMe;