import {Box, Typography, Container, Grid} from '@mui/material'

const Resume = () =>{
    return(
        <>
        <Box sx={{width:'100%', marginTop:'5rem'}}>
        <Container>
            <Box sx={{padding:'1rem', border: '10px solid #3aa82d', borderRadius:'10px'}}>
                <Typography variant='body1' sx={{fontSize:{xs:'1.2rem', md:'2rem'}, fontFamily:'Oswald', color:'#825323'}}>
                ¡Bienvenido a mi rincón digital! Soy Leonardo Espinosa, un apasionado desarrollador de software con un enfoque dinámico y creativo en el mundo del desarrollo web. Egresado de la Universidad Politécnica de Chiapas, mi viaje en el fascinante universo de la programación comenzó con una sólida base académica y ha evolucionado hacia la creación de experiencias web cautivadoras.
                <span style={{color:'#3aa82d', cursor:'pointer'}} onClick={()=>{window.location.href='/#/acerca'}} >&nbsp;&nbsp;Ver más...</span>
                </Typography>
            </Box>
        </Container>
        </Box>
        </>
    )
}

export default Resume;
