import Invitation from "./components/invitation";
import Works from "./components/works";



const WorksContainer = () =>{
    return(
        <>
        <Works/>
        <Invitation/>
        </>
    )
}

export default WorksContainer;