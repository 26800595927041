import { Box, Grid, Typography, Button } from "@mui/material";
import AvatarLeo from '../../../images/AVATAR.webp'


function Intro() {
    return(
        <>
        <Box sx={{ width:'100%', marginTop:{md:'4rem', xs:'0.5rem'}}}>
            <Grid container spacing={2} style={{display:'flex'}}>
                <Grid  item md={5} sm={12}>
                    <Box sx={{width:'100%', height:'100%', textAlign:{md:'right', xs:'center'}}} >
                        <img src={AvatarLeo} width='60%' style={{transform: 'scaleX(-1)'}} />
                    </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12} >
                    <Box sx={{width:'100%', height:'100%',display:'flex', alignItems:'center'}} >
                        <Box sx={{width:'80%',margin: '0 auto'}}>
                            <Typography gutterBottom variant="h3" sx={{fontSize:{sm:'3.5rem', xs:'2.2rem'}, textAlign:{sm:'left', xs:'center'}, color:'#825323'}} fontFamily='Oswald' >
                                ¡Hola, Soy Leonardo Espinosa! Bienvenido a mi sitio web
                            </Typography>
                            <Typography variant="h6" sx={{fontFamily:'Oswald', color:'#3aa82d'}}>
                                - Desarrollador de Software
                            </Typography>
                            <Box sx={{width:'100%', display:'flex', justifyContent:'center', marginTop:'1.5rem',}}>
                                <Button size="large" href="/#/contacto" variant="outlined" color="success" sx={{  margin:'auto'}}>
                                    Contactar
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={1} sm={false} xs={false} />
            </Grid>
        </Box>
        </>
    )
}

export default Intro;