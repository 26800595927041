import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Instagram } from "@mui/icons-material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from "@mui/material";
import LOGO from '../../images/logoLE.png';


function Footer() {
    return (
        <Box
          component="footer"
          sx={{
            marginTop:'5rem',
            backgroundColor:'#fffff',
            color:'#3aa82d',
            boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)',  
            border: '3px solid #ccc',  
            paddingTop: '2rem'
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={4}>
              <div key='1'>
                  <Link href='/' sx={{display:'flex', textDecoration:'none', alignItems:'center'}}>
                  <img
                    src={LOGO}
                    alt='Logo letra Neowork'
                    style={{ height:'100px', cursor:'pointer' }}
                  />
                  <Typography variant='h1' sx={{fontSize:'2.5rem', color:'#3aa82d', textDecoration:'none', fontFamily:'Oswald'}}>Leonardo Espinosa</Typography>
                  </Link>
              </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6"  gutterBottom sx={{fontWeight: '600'}}>
                  Contacto
                </Typography>
                <Typography variant="body2" sx={{fontWeight: '600'}}>
                  Correo: devleoespinosa@gmail.com
                </Typography>
                <Typography variant="body2" sx={{fontWeight: '600'}}>
                  Telefono: <a href="tel:9613367173" style={{color:'#3aa82d'}}>(961) 178-8970</a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6"  gutterBottom sx={{fontWeight: '600'}}>
                  Sígueme
                </Typography>
                <Link component='a' target="_blank" href="https://www.linkedin.com/in/leonardo-espinosa-arevalo/" color="inherit">
                  <LinkedInIcon />
                </Link>
                <Link
                  component='a' target="_blank"
                  href="https://www.instagram.com/amoureuse.py/"
                  color="inherit"
                  sx={{ pl: 1, pr: 1 }}
                >
                  <Instagram />
                </Link>
                <Link component='a' target="_blank" href="https://wa.me/+5219611788970"  color="inherit">
                  <WhatsAppIcon />
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography variant="body2"  align="center" sx={{fontWeight: '600'}}>
                {"Copyright © "}
                <Link color="inherit" component='a' target="_blank" href="/">
                  devleoespinosa.com
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
          </Container>
        </Box>
      );
}

export default Footer;